import React, {useEffect, useState} from "react"
//import Axios from 'axios'
import axios, { Axios } from 'axios'
import { useLocation } from 'react-router-dom';

export default function Register() { 

    const [usernameReg, setUsernameReg] = useState('')
    const [passwordReg, setPasswordReg] = useState('')
    const [emailReg, setEmailReg] = useState('')

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const[loginStatus, setLoginStatus] = useState('')
    const[loginStatusState, setLoginStatusState] = useState(0)

    const [turnLogin, setTurnLogin] = useState(0)
    const [turnRegister, setTurnRegister] = useState(0)

    const [alertState, setAlertState] = useState(0)
    const [alertState2, setAlertState2] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [alertStateHeight, setAlertStateHeight] = useState(0)
    const [description, setDescription] = useState('')
    const [userNameResendCode, setUserNameResendCode] = useState('')

    const [alertStateRemovedPage, setAlertStateRemovedPage] = useState(0)

    axios.defaults.withCredentials = true

    const register =() => {
        //alert("HELLO")
        /*Axios.post('http://${process.env.REACT_APP_BACKEND}/register', {
            username: usernameReg, 
            password: passwordReg
        }).then((response) => {
            console.log(response)
            })*/
        
        axios.post(`${process.env.REACT_APP_BACKEND}/register`, {
            username: usernameReg, 
            email: emailReg,
            password: passwordReg
        }).then((response) => {
            //console.log('Full axios response register', response)
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
        }).catch((err) => {
            console.log(err.message)
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
        })
        
    }

    const login =() => {
        
        axios.post(`${process.env.REACT_APP_BACKEND}/login`, {
            username: username, 
            password: password
        }).then((response) => {

            if (response.data.message){
                if(
                    response.data.message === "You didn't verify your email yet" || 
                    response.data.message === "Wrong username/password combination!" ||
                    response.data.message === "Wrong password" ||
                    response.data.message === "User doesn't exist"
                ){
                    setUserNameResendCode(username)
                    setLoginStatus(response.data.message)
                }
                if(response.data.loggedIn === true){
                    setLoginStatusState(0)
                    turnOnMainPage()
                }else{
                    setLoginStatusState(1)
                }
            } else if (response.data.loggedIn && response.data.user.length > 0) {
                //setLoginStatus(response.data.user[0].username)
                setLoginStatusState(0)
                turnOnMainPage()
            }
            else{
                console.error('Unexpected response format', response.data)
                setLoginStatus('Error occured')
                setLoginStatusState(1)
            }
            //console.log(response.data)
        }).catch((err) => {
            console.error('Error', err.message)
            setLoginStatus('Error occured')
            setLoginStatusState(1)
        })
        
    }

    const resendVerificationCode =(usernameResendCodeFunction) => {
        axios.post(`${process.env.REACT_APP_BACKEND}/resendVerificationCode`, {
            username: usernameResendCodeFunction
        }).then((response) => {
            if (response.data.message){
                setAlertState2(1);
                const descr = response.data.message
                setDescription(descr)
            } 
            //console.log(response.data)
        }).catch((err) => {
            console.error('Error', err.message)
            setAlertStateWrong(1);
            const descrWrong = err.message
            setDescription(descrWrong)
            setLoginStatusState(1)
        })
    }

    const turnOnMainPage =() => {
        window.location.href = `/`;    
    }

    const urlParams2 = new URLSearchParams(window.location.search);
    const valueFromLink = urlParams2.get('message');

    useEffect(() => {
        if(valueFromLink){
            setAlertStateRemovedPage(1)
            setTimeout(() => {
                setAlertStateRemovedPage(0);
              }, 5000);
        }
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/login`).then((response) =>{
            if (response.data.loggedIn == true) {
                //setLoginStatus(response.data.user[0].username)
            }
        })
    }, [])

    const turnOnLogin =() => {
        setTurnLogin(0)       
    }

    const turnOnRegister =() => {
        setTurnLogin(1)       
    }

    const validateUsername = (value) => {
        const regex = /^$|^[a-zA-Zа-яА-ЯёЁ0-9-_]+$/;
        if (value.includes(' ')) {
            setUsernameError('Username cannot contain spaces');
            return false;
        } else if (!regex.test(value)) {
            setUsernameError('Username can only contain cyrillic or latin letters, numbers, hyphens, and underscores');
            return false;
        } else if (value.length > 30) {
            setUsernameError('Maximum 30 characters allowed for username');
            return false;
        } else {
            setUsernameError('');
            return true;
        }
    };

    const validateEmail = (value) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!regex.test(value)) {
            setEmailError('Invalid email format');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const validatePassword = (value) => {
        if (value.length > 30) {
            setPasswordError('Password must be maximum 30 characters long');
            return false; // Password longer than 30 characters
        } else if (value.includes(' ')) {
            setPasswordError('Password cannot contain spaces');
            return false;
        } else if (value.length < 8) {
            setPasswordError('Password must be at least 8 characters long');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const handleGoogleSignIn = () => {
        const CLIENT_ID = process.env.REACT_APP_CLIENT_ID_REGISTER; // Replace with your OAuth 2.0 client ID
        const REDIRECT_URI = `${process.env.REACT_APP_BACKEND}/auth/google/register/callback`; // Replace with your redirect URI
      
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
          `scope=https://www.googleapis.com/auth/userinfo.profile` +
          `+https://www.googleapis.com/auth/userinfo.email` +
          `&response_type=code` +
          `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}` +
          `&client_id=${CLIENT_ID}`;
      
        window.location.href = authUrl;
      };

      const closeAlert =() => {
        setAlertState(0)
        setAlertStateHeight(0)
        setAlertStateRemovedPage(0)
        setAlertState2(0)
        setAlertStateWrong(0)
        setDescription('')
    }

    const restorePasswordLink =() => {
        window.location.href = `/restorepasswordrequest`;
      }

      const handleGoogleLogin = async () => {
        try {
          // Redirect the user to the Google authentication page
          window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
            'scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email' +
            '&response_type=code' +
            `&redirect_uri=${process.env.REACT_APP_BACKEND}/auth/google/login/callback` + // Replace with your callback URL
            '&client_id=477112517090-mi4pfj7vneja6l5c214s0gfim67mrinu.apps.googleusercontent.com' + // Replace with your client ID
            '&service=lso' +
            '&o2v=2' +
            '&theme=glif' +
            '&flowName=GeneralOAuthFlow';
        } catch (error) {
          console.error('Error:', error);
        }
      };

      
    const [registerPageAlert, setRegisterPageAlert] = useState(0)
      const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('result');
      useEffect(() => {
        if (message === 'done') {
            axios.get(`${process.env.REACT_APP_BACKEND}/api/getRegistrationMessage`)
            .then(response => {
                const data = response.data;
                //console.log('Received registration message from server:', data.registrationMessage);
                if (data.registrationMessage === 'You were registered successfully!') {
                    setAlertState(1);
                    setAlertStateHeight(1);
                    const descr = data.registrationMessage
                    setDescription(descr)
                } else{             
                    setAlertStateWrong(1);
                    const descrWrong = data.registrationMessage
                    setDescription(descrWrong)
                }
            })
            .catch(error => console.error('Error retrieving registration message:', error));
        }
      }, []);
      
      const location = useLocation();

      useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const error = searchParams.get('error');
    
        if (error) {
          let errorMessage = '';
          if (error === 'user_not_found') {
            errorMessage = 'User was not found. Please register.';
          }
          setAlertStateWrong(1);
          setDescription(errorMessage)
        }
      }, [location]);

    return (
        <div className="columnRegisterPage">
        <div className="formColumn">
        <div className={ turnLogin === 0 ? "login" : "notDisplayed" }>
           <form className="loginForm">
                <label className="label">NickName</label>

                <input type="text" className="buttonFormName" 
                    onChange={(e) => {
                        setUsername(e.target.value)
                    }}/>

                <label className="label">Password</label>

                <input type="password" className="buttonFormPassword" 
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}/>

                <button type="button" className="buttonFormSubmit2RegisterPage" onClick={login}>Login</button>
            </form>
        </div>

        <div className={ turnLogin === 1 ? "notDisplayed" : "rowButtonsLogin" }>
        <div className="switchRegisterButtonGoToRegister" onClick={() => {turnOnRegister()}}><span className="switchRegisterButtonSpan">Go to register</span></div>  
        <div className="loginWithGoogleButton">
            <svg xmlns="http://www.w3.org/2000/svg" className="googleSignSvg" width="20px" height="20px" viewBox="0 0 32 32" fill="none">
            <path d="M30.0014 16.3109C30.0014 15.1598 29.9061 14.3198 29.6998 13.4487H16.2871V18.6442H24.1601C24.0014 19.9354 23.1442 21.8798 21.2394 23.1864L21.2127 23.3604L25.4536 26.58L25.7474 26.6087C28.4458 24.1665 30.0014 20.5731 30.0014 16.3109Z" fill="#4285F4"/>
            <path d="M16.2863 29.9998C20.1434 29.9998 23.3814 28.7553 25.7466 26.6086L21.2386 23.1863C20.0323 24.0108 18.4132 24.5863 16.2863 24.5863C12.5086 24.5863 9.30225 22.1441 8.15929 18.7686L7.99176 18.7825L3.58208 22.127L3.52441 22.2841C5.87359 26.8574 10.699 29.9998 16.2863 29.9998Z" fill="#34A853"/>
            <path d="M8.15964 18.769C7.85806 17.8979 7.68352 16.9645 7.68352 16.0001C7.68352 15.0356 7.85806 14.1023 8.14377 13.2312L8.13578 13.0456L3.67083 9.64746L3.52475 9.71556C2.55654 11.6134 2.00098 13.7445 2.00098 16.0001C2.00098 18.2556 2.55654 20.3867 3.52475 22.2845L8.15964 18.769Z" fill="#FBBC05"/>
            <path d="M16.2864 7.4133C18.9689 7.4133 20.7784 8.54885 21.8102 9.4978L25.8419 5.64C23.3658 3.38445 20.1435 2 16.2864 2C10.699 2 5.8736 5.1422 3.52441 9.71549L8.14345 13.2311C9.30229 9.85555 12.5086 7.4133 16.2864 7.4133Z" fill="#EB4335"/>
            </svg>
            <span className="switchRegisterButtonSpan" onClick={() => {handleGoogleLogin()}}>Google login</span>
        </div>
        </div>
        <div className="loginStatusDiv">
        <div className={turnLogin === 1 ? "notDisplayed" : (loginStatus === '' ? "notDisplayed" : "loginStatusMessage")}>{loginStatus}</div>
        <div className={loginStatus == "You didn't verify your email yet" ? 'divResendCode' : 'notDisplayed'} onClick={() => {resendVerificationCode(userNameResendCode)}}>Resend verification code</div>
        </div>

        <div className={ turnLogin === 1 ? "notDisplayed" : "recoverPasswordRegisterPage" }>Forgot password? You can <span className="recoverPasswordChangePasswordPageSpan" onClick={() => {restorePasswordLink()}}>restore</span> it with your email</div>

        <div className={ turnLogin === 0 ? "notDisplayed" : "register" }>
            <form className="registerForm">
                <div className="registerFormColumn">
                <label className="label">NickName</label>

                <input type="text" className="buttonFormNickNameRegister" 
                    onChange={(e) => {
                        setUsernameReg(e.target.value)
                        validateUsername(e.target.value);
                    }}/>
                {usernameError && <span className="error">{usernameError}</span>}
                </div>

                <div className="registerFormColumn">
                <label className="label">Email</label>

                <input type="text" className="buttonFormNickNameRegister"
                 onChange={(e) => {
                    setEmailReg(e.target.value)
                    validateEmail(e.target.value);
                }}/>
                {emailError && <span className="error">{emailError}</span>}
                </div>
                
                <div className="registerFormColumn">
                <label className="label">Password</label>

                <input type="password" className="buttonFormPassword"
                    onChange={(e) => {
                        setPasswordReg(e.target.value)
                        validatePassword(e.target.value);
                    }}/>

                {passwordError && <span className="error">{passwordError}</span>}
                </div>
                
                <button type="button" className="buttonFormSubmitRegister" onClick={() => {register()}} disabled={true}>Register</button>
            </form>
        </div>
        
        <div className={ turnLogin === 0 ? "notDisplayed" : "rowButtonsRegister" }>
        <div className="switchRegisterButtonGoToLogin" onClick={() => {turnOnLogin()}}><span className="switchRegisterButtonSpan">Go to login</span></div>
        <div className="registerWithGoogleButton">
        <svg xmlns="http://www.w3.org/2000/svg" className="googleSignSvg" width="20px" height="20px" viewBox="0 0 32 32" fill="none">
        <path d="M30.0014 16.3109C30.0014 15.1598 29.9061 14.3198 29.6998 13.4487H16.2871V18.6442H24.1601C24.0014 19.9354 23.1442 21.8798 21.2394 23.1864L21.2127 23.3604L25.4536 26.58L25.7474 26.6087C28.4458 24.1665 30.0014 20.5731 30.0014 16.3109Z" fill="#4285F4"/>
        <path d="M16.2863 29.9998C20.1434 29.9998 23.3814 28.7553 25.7466 26.6086L21.2386 23.1863C20.0323 24.0108 18.4132 24.5863 16.2863 24.5863C12.5086 24.5863 9.30225 22.1441 8.15929 18.7686L7.99176 18.7825L3.58208 22.127L3.52441 22.2841C5.87359 26.8574 10.699 29.9998 16.2863 29.9998Z" fill="#34A853"/>
        <path d="M8.15964 18.769C7.85806 17.8979 7.68352 16.9645 7.68352 16.0001C7.68352 15.0356 7.85806 14.1023 8.14377 13.2312L8.13578 13.0456L3.67083 9.64746L3.52475 9.71556C2.55654 11.6134 2.00098 13.7445 2.00098 16.0001C2.00098 18.2556 2.55654 20.3867 3.52475 22.2845L8.15964 18.769Z" fill="#FBBC05"/>
        <path d="M16.2864 7.4133C18.9689 7.4133 20.7784 8.54885 21.8102 9.4978L25.8419 5.64C23.3658 3.38445 20.1435 2 16.2864 2C10.699 2 5.8736 5.1422 3.52441 9.71549L8.14345 13.2311C9.30229 9.85555 12.5086 7.4133 16.2864 7.4133Z" fill="#EB4335"/>
        </svg>
        <span className="switchRegisterButtonSpan" onClick={handleGoogleSignIn}>Register with Google</span>
        </div>
        </div>
        </div>
        <div className={alertStateWrong ? 'resendCodeInfo' : 'notDisplayed' }>If you want to resend verification code, try to login</div>

        <div className={alertState ? (alertStateHeight ? 'alertDivRegister3' : 'alertDivRegisterMain') : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkRegister">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDivRegister textDiv-1">Registered</span>
                    <span className="textDivRegister textDiv-2">You were registered successfully!</span>
                    <span className={alertStateHeight ? 'notDisplayed' : 'checkEmailMessage'}>Check your email now to verify it</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>
                </div>

                <div className={alertState2 ? 'alertDivRegister' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkRegister">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDivRegister textDiv-1">Sent</span>
                    <span className="textDivRegister textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>
                </div>

                <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDivWrong">
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>
            </div>

            
            <div className={alertStateRemovedPage ? 'alertDiv' : 'notDisplayed' }>
  
            <div className="alertDiv-content">
                <i className="check">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Success</span>
                <span className="textDiv textDiv-2">Your page was removed</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            <div className="progressDiv active"></div>
            </div>
            
        </div>
    )
}