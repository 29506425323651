import React, { useEffect, useState } from "react"
import axios from 'axios'
import ListHSK4Component from "./HSK4component";
import { useLocation, Navigate } from 'react-router-dom';

export default function ListHSK4LimitsSending() {

        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const id = Number(queryParams.get('id'));

        const allowedIds = new Set([...Array(47).keys()].map(x => x + 1)); // Set of numbers 1-24

        const [isPaidHSK4, setIsPaidHSK4] = useState(3)

        useEffect(() => {
         const is_paidHSK4 = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk4'}`);
              //console.log(response.data.paid);
              if (response.data.paid === 1) {
                setIsPaidHSK4(response.data.paid);
              } else if (response.data.paid === 0) {
                setIsPaidHSK4(2);
              }
            } catch (error) {
              console.error("Error fetching payment status:", error);
            }
          };
          is_paidHSK4()
        }, [])

        if (id != 1 && isPaidHSK4 === 2){
            return <Navigate to="/courses" />;
        }

        if (!allowedIds.has(id)) {
            return <Navigate to="/listHSK4" />;
        }

        const limit1 = (id - 1) * 25 + 1; // Start of the range
        const limit2 = id * 25;       

    return <ListHSK4Component limit1={limit1} limit2={limit2} />;
}