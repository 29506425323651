import React, { useEffect, useState } from "react"
import TextToSpeech from "../TextToSpeech"
import axios from 'axios'

export default function Description({character, remove, removeQuestionAndDescription}) {
    
    const [showInfoState, setShowInfoState] = useState(0)

    const showTranscriptionFunction =() => {
        if(showInfoState === 0) {
            setShowInfoState(1)
        }else{
            setShowInfoState(0)
        }
    }

    const text = character.character;

    //console.log('text', text)

    const [isClicked, setIsClicked] = useState(false);

    const ignoreTheWordFunction =(character) => {

        setIsClicked(true)

        axios.post(`${process.env.REACT_APP_BACKEND}/ignoreTheWordFunction`, {
            character: character,
            hsk:'_hsk5'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            removeQuestionAndDescription()
            setIsClicked(false)
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    return (
        <>
            {/*{JSON.stringify(character)}
            <button onClick={() => {remove()}}>OK</button>*/}
            <div className="description">
                        <div className="mainWordDiv">
                        <p>{character.character}</p>
                        <div className={showInfoState === 0 ? "notDisplayed" : "mainWordTranscr"}>{character.transcription}</div>
                        <div className="mainWordDiv2">
                        <div className="transcriptionSVG" onClick={() => {showTranscriptionFunction()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20">
                        <rect x="0" fill="none" width="20" height="20"/>
                        <g fill={showInfoState === 0 ? "#444444" : "#1662b9"}>
                        <path d="M11 7H9.49c-.63 0-1.25.3-1.59.7L7 5H4.13l-2.39 7h1.69l.74-2H7v4H2c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h7c1.1 0 2 .9 2 2v2zM6.51 9H4.49l1-2.93zM10 8h7c1.1 0 2 .9 2 2v7c0 1.1-.9 2-2 2h-7c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2zm7.25 5v-1.08h-3.17V9.75h-1.16v2.17H9.75V13h1.28c.11.85.56 1.85 1.28 2.62-.87.36-1.89.62-2.31.62-.01.02.22.97.2 1.46.84 0 2.21-.5 3.28-1.15 1.09.65 2.48 1.15 3.34 1.15-.02-.49.2-1.44.2-1.46-.43 0-1.49-.27-2.38-.63.7-.77 1.14-1.77 1.25-2.61h1.36zm-3.81 1.93c-.5-.46-.85-1.13-1.01-1.93h2.09c-.17.8-.51 1.47-1 1.93l-.04.03s-.03-.02-.04-.03z"/>
                        </g>
                        </svg>
                        </div>
                        </div>
                        
                        <TextToSpeech text={text} />

                        <div className="addToIgnored" onClick={() => {if (!isClicked) {ignoreTheWordFunction(character.character)}}}>
                        <div className="divMinus">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24" fill="none">
                        <path d="M9 12H15" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </div>
                            <div className="informationAboutIgnoreWord" id="information">Mark the word as learnt</div>    
                        </div>
                        </div>
                        <p className="translationWord">{character.translation}</p>
                        <div className="radicalsDescriptionDiv">
                        <p>{character.hintsRadicals}</p>
                        <div className={showInfoState === 0 ? "notDisplayed" : "radicalsDescriptionTranscr"}>{character.hintsRadicalTranscriptions}</div>
                        </div>
                        <p className="radicalsTranslation">{character.hintsRadicalTranslations}</p>
                        <p className="wordDescription">{character.info}</p>
                        <br/>
                        <button className="button" onClick={() => {remove()}}>Go to Quiz</button>
                    </div>
        </>
    )    
}