import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useRef } from "react";

export default function List() {

    
    const [data, setData] = useState([])

    const getCharactersData =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/quizHSK2/getCharactersForList`).then((response) => {
            if (response.data.characters){
                setData(response.data.characters)
                //console.log('data is', response.data.characters)
                //console.log(response.data.characters)
            } 
        });
     }
    
     useEffect(() => {
        getCharactersData()
    }, []);

const CharactersList = ({character, index}) => {

    const [score, setScore] = useState(0)
    const [maxQuestions, setMaxQuestions] = useState(0)
    const [hint, setHint] = useState(0)

    const [scoreLoaded, setScoreLoaded] = useState(false)
    const [maxQuestionsLoaded, setMaxQuestionsLoaded] = useState(false)

    useEffect(() => {
        
        const generalCharacterResult = character.character
        //console.log(generalCharacterResult)
        generalScore()
        amountOfAnsweredCharactersFunction()
        isIgnored()
    }, [character]);

    const generalScore =() => {
        //console.log(character.character.character)
        const generalCharacterResult = character.character
        const url = `${process.env.REACT_APP_BACKEND}/generalScoreForOneCharacter?generalCharacterResult=${generalCharacterResult}&hsk=${'_hsk2'}`;
        
        //console.log('url', url)
        axios.get(url).then((response) => {
            //setPost(response.data);
            setMaxQuestionsLoaded(true)
            if (response.data.amount){
                setMaxQuestions(response.data.amount)
            } 
        });       
    }

    const amountOfAnsweredCharactersFunction =() => {
    const url = `${process.env.REACT_APP_BACKEND}/amountOfAnsweredCharacters?characterId=${character.id}&character=${character.character}&hsk=${'_hsk2'}`;
        
    axios.get(url).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        setScoreLoaded(true)
        if (response.data.amountOfCharacters){
            setScore(response.data.amountOfCharacters)
        } 
    });
 }

    //console.log('maxQuestions is', maxQuestions)
    //console.log(character.character.character)
    //console.log('score is', score)
    const percentage = score / maxQuestions
    //console.log('percentage is', percentage)  

    const [turnOnFixing, setTurnOnFixing] = useState(0);

    const [restartedCharacter, setRestartedCharacter] = useState(0);

    const [restartedCharacterIgnore, setRestartedCharacterIgnore] = useState(0);

    const [isClicked, setIsClicked] = useState(false);

    const [ignoredWord, setIgnoredWord] = useState(0);

    const learnAgainFunction =(character) => {

        setIsClicked(true)

        axios.post(`${process.env.REACT_APP_BACKEND}/learnAgainFunction`, {
            character: character,
            hsk:'_hsk2'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            setRestartedCharacter(1)
            setTurnOnFixing(0)
            setIgnoredWord(0)
            setRestartedCharacterIgnore(0)
            setIsClicked(false)
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    const isIgnored =() => {

        axios.post(`${process.env.REACT_APP_BACKEND}/isIgnoredCheck`, {
            character: character.character,
            hsk:'_hsk2'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            const ignored = response.data.ignored;
            //console.log('response.data.ignored', response.data.ignored)
            setIgnoredWord(ignored)
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    const ignoreTheWordFunction =(character) => {

        setIsClicked(true)

        axios.post(`${process.env.REACT_APP_BACKEND}/ignoreTheWordFunction`, {
            character: character,
            hsk:'_hsk2'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            setIgnoredWord(1)
            setTurnOnFixing(0)
            setRestartedCharacter(0)
            setRestartedCharacterIgnore(1)
            setIsClicked(false)
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }


    const documentRef = useRef(document); 
    useEffect(() => {
            const handleClick = (event) => {
                // Handling first block
                const block1 = document.getElementById(`absoluteDivFix${index}`);
                const open1 = document.getElementById(`editWordsListButton${index}`);
                if (open1 && open1.contains(event.target)) {
                    setTurnOnFixing(1);
                } else if (block1 && !block1.contains(event.target)) {
                    setTurnOnFixing(0);
                }
             }

             documentRef.current.addEventListener('click', handleClick);
             return () => {
                documentRef.current.removeEventListener('click', handleClick);
            };
            }, []);

    return (
        <div className="descriptionList">
            <p className="mainWordList" style={{color: ignoredWord === 1 ? "gray" : "inherit"}}>{character.character}</p>
            <p className="translationWordList" style={{color: ignoredWord === 1 ? "gray" : "inherit"}}>{character.translation}</p>
            
            <p className="radicalsDescriptionList" style={{color: ignoredWord === 1 ? "gray" : "inherit"}}>{character.hintsRadicals}</p>
            <p className="radicalsTranslationList" style={{color: ignoredWord === 1 ? "gray" : "inherit"}}>{character.hintsRadicalTranslations}</p>
            <p className="wordDescriptionList" style={{color: ignoredWord === 1 ? "gray" : "inherit"}}>{character.info}</p>
            <br/>
            <div className="buttonListBox">
                <div className={ignoredWord === 1 ? "buttonList" : (percentage ? (restartedCharacter === 1 ? "buttonListBox" : (percentage === 1 ? "buttonList" : "buttonList2")) : "buttonListBox")} style={{height: ignoredWord === 1 ? '50px' : (percentage ? `${percentage * 50}px` : '50px')}}></div>
            </div>

            <div className={turnOnFixing === 0 ? "editWordsListButton": "notDisplayed"} id={`editWordsListButton${index}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" data-name="Layer 1"><path d="M8.5,10a2,2,0,1,0,2,2A2,2,0,0,0,8.5,10Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,8.5,17Zm7-10a2,2,0,1,0-2-2A2,2,0,0,0,15.5,7Zm-7-4a2,2,0,1,0,2,2A2,2,0,0,0,8.5,3Zm7,14a2,2,0,1,0,2,2A2,2,0,0,0,15.5,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,15.5,10Z"/>
            </svg>
            </div>


            <div className={maxQuestionsLoaded === true && scoreLoaded === true ? (turnOnFixing === 1 ? "absoluteDivFix" : "notDisplayed") : "notDisplayed"} id={`absoluteDivFix${index}`}>
            <div className="divForLearnWordSvgAbsolute2">
                <svg className={restartedCharacterIgnore === 1 ? "divIgnoreWord" : (percentage === 1 ? (restartedCharacter === 0 ? "learnAgainSvgAbsolute" : "notDisplayed"): "notDisplayed")} onClick={() => {learnAgainFunction(character.character)}} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 -8.14 62.451 62.451">
                    <g id="Group_35" data-name="Group 35" transform="translate(-782.964 -1356.609)">
                    <path id="Path_92" data-name="Path 92" d="M798.022,1369.359v23.226h.034c.353,4.555,7.685,8.2,16.7,8.2s16.347-3.641,16.7-8.2h.033v-23.226Z" fill="#d1d3d4" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                    <path id="Path_93" data-name="Path 93" d="M843.415,1373.207l-29.225,14.6-29.227-14.6,29.227-14.6Z" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                    <line id="Line_40" data-name="Line 40" y1="19.235" transform="translate(784.964 1374.361)" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                    </g>
                </svg>
            </div>

            <div className={ignoredWord === 1 ? "notDisplayed" : "divIgnoreWord"}>
            <svg onClick={() => {ignoreTheWordFunction(character.character)}} xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                <g id="Edit / Remove_Minus_Circle">
                <path id="Vector" d="M8 12H16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>
            </div>
        </div>
    </div>
      )
}

    return (
        <>
        { data.length != 0 ? 
        <div className="list">
             {
                            data.length != 0 ? (data.map((character, index) => {
                                return <CharactersList character={character} index={index} key={index}/>
                            }))
                            : <div></div>
            }           
        </div>
        :
        <div className="main"> 
        <div className="waiting">
            <button className="threeDotsWaiting" id="id-three-dots4">
                <div className="blackDotWaiting"></div>
                <div className="blackDotWaiting"></div>
                <div className="blackDotWaiting"></div>
            </button>
        </div>
        </div>
        }
        </>
    )  
}