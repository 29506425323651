import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useRef } from "react";
import { useLocation } from 'react-router-dom';

export default function Settings() {

    const location = useLocation();

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')
    const [askingBox, setAskingBox] = useState(0)

    const question_one = "All the users"
    const question_two = "All the users"
    const question_three = "Yes"
    const question_four = "Yes"

    const [answers, setAnswers] = useState({
        question1: question_one,
        question2: question_two,
        question3: question_three,
        question4: question_four,
      });

      axios.defaults.withCredentials = true

      const handleSubmit = async (e) => {
        e.preventDefault();

        if(isPaid === 2) {
          setNotificationForSettingsSubmit(1)
        }else{
          axios.post(`${process.env.REACT_APP_BACKEND}/saveSettings`, {
              answers: answers
          }).then((response) => {
              //console.log(response.data.message)
              //alert(response.data.message)
              setAlertState(1);
              const descr = response.data.message
              setDescription(descr)
              setTimeout(() => {
                  setAlertState(0);
                  setDescription('')
                }, 5000);
          }).catch((err) => {
              setAlertStateWrong(1);
              const descrWrong = err.response.data.message
              setDescription(descrWrong)
              //console.log(JSON.stringify(err.response.data.message))
          })
        }
       
      };

      const handleInputChange = (questionId, value) => {
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [questionId]: value,
        }));
      };

      const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setDescription('')
    }

    const [notificationSettingsChangeEmail, setNotificationSettingsChangeEmail] = useState(0)
    const [notificationSettingsChangePassword, setNotificationSettingsChangePassword] = useState(0)
    const [notificationForSettingsSubmit, setNotificationForSettingsSubmit] = useState(0)

    const linkToChangeEmail =() => {
        if(loggedInWithGoogle === 1){
          window.location.href = `/changeEmail`;
        } else{
          setNotificationSettingsChangeEmail(1)
        }
      }

      const linkToChangePassword =() => {
        if(loggedInWithGoogle === 1){
          window.location.href = `/changePassword`;
        }else{
          setNotificationSettingsChangePassword(1)
        }
      }

      const notificationBox =() => {
        alert(loggedInWithGoogle)
        if(loggedInWithGoogle === 0){
          setNotificationForSettingsSubmit(1)
        } 
      }

      const removeChatFunction = () => {
        setAskingBox(1)
      };

      const removeChatFunctionOff = () => {
        setAskingBox(0)
      };

      const linkToLoginPage2 =() => {
          window.location.href = `/register?message=removedPage`;
      }

      const linkToLoginPage =() => {
        window.location.href = `/register`;
    }

      const removePageFunction = async () => {
        axios.post(`${process.env.REACT_APP_BACKEND}/removePage`, {
        }).then((response) => {
            //console.log(response.data.message)
            //alert(response.data.message)
            const descr = response.data.message
            linkToLoginPage2(descr)
        }).catch((err) => {
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            //console.log(JSON.stringify(err.response.data.message))
        })
      };

      const handleLogout = async () => {
        try {
          await axios.get(`${process.env.REACT_APP_BACKEND}/logout`); // Send a GET request to the server to logout
          // Optionally, you can redirect the user to the login page or do other actions upon successful logout
          linkToLoginPage()
        } catch (error) {
          console.error('Error logging out:', error);
          if (error.response) {
            console.error('Server responded with:', error.response.data);
          }
        }
      };

      const [loggedInWithGoogle, setLoggedInWithGoogle] = useState(2)

      useEffect(() => {
        const isGoogleLoggedIn = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/isLoggedInWithGoogle`);
            //console.log('response.data.loggedInWay', response.data.loggedInWay)
            if (response.data.loggedInWay === 'usualLogin') {
              setLoggedInWithGoogle(1);
            } else if (response.data.loggedInWay === 'googleLoggedIn') {
              setLoggedInWithGoogle(0);
            }
          } catch (error) {
            console.error('Error checking login status:', error);
            if (error.response) {
              console.error('Server responded with:', error.response.data);
            }
          }
        };
        isGoogleLoggedIn()
    }, [location.search]);

    const [isPaid, setIsPaid] = useState(3)

    useEffect(() => {
      const is_paid = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid_generally`);
          //console.log(response.data.paid);
          if (response.data.paid === 1) {
            setIsPaid(response.data.paid);
          } else if (response.data.paid === 0) {
            setIsPaid(2);
          }
        } catch (error) {
          console.error("Error fetching payment status:", error);
        }
      };
      is_paid()
    }, [location.search]);

    const blockRef = useRef(null);

    useEffect(() => {
      const handleClick = (event) => {
        if (blockRef.current && !blockRef.current.contains(event.target)) {
          setNotificationSettingsChangePassword(0);
        }
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [location.search]);

    const blockRef3 = useRef(null);

    useEffect(() => {
      const handleClick = (event) => {
        if (blockRef3.current && !blockRef3.current.contains(event.target)) {
          setNotificationSettingsChangeEmail(0)
        }
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [location.search]);

    const blockRef2 = useRef(null);
  
    useEffect(() => {
      const handleClick = (event) => {
        if (blockRef2.current && !blockRef2.current.contains(event.target)) {
          setNotificationForSettingsSubmit(0)
        }
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [location.search]);

    const [settingsOwn_info, setSettingsOwn_info] = useState('')
    const [settingsFriends, setSettingsFriends] = useState('')
    const [settingsGroup_chat, setSettingsGroup_chat] = useState('')
    const [settingsAssociations, setSettingsAssociations] = useState('')

    useEffect(() => {
      const get_settings = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/settingsInfo`);
          setAnswers({
            question1: response.data.own_info,
            question2: response.data.friends,
            question3: response.data.group_chat,
            question4: response.data.associations,
          });
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      };
      get_settings()
    }, [location.search]);

    return (
        <div className="settingsPage">
            
            <div className="settingsPageCommonDiv">
            <div className="settingsPageContentDiv">
                <form onSubmit={handleSubmit} className="settingsPageForm">
                <div className="settingsPageFormDiv">
                <div className="divForm">
                <label htmlFor="question1">Information about me is visible to</label>
                <select
                    id="question1"
                    name="question1"
                    value={answers.question1}
                    onChange={(e) => handleInputChange("question1", e.target.value)}
                    className="selectSettingsDivForm"
                >
                    <option value={question_one} disabled={isPaid === 2}>All the users</option>
                    <option value="Only to my friends" disabled={isPaid === 2}>Only to my friends</option>
                    <option value="Only to me" disabled={isPaid === 2}>Only to me</option>
                </select>
                </div>

                <div className="divForm"> 
                <label htmlFor="question2">My friends are visible to</label>
                <select
                    id="question2"
                    name="question2"
                    value={answers.question2}
                    onChange={(e) => handleInputChange("question2", e.target.value)}
                    className="selectSettingsDivForm"
                >
                    <option value={question_two} disabled={isPaid === 2}>All the users</option>
                    <option value="Only to my friends" disabled={isPaid === 2}>Only to my friends</option>
                    <option value="Only to me" disabled={isPaid === 2}>Only to me</option>
                </select>
                </div>

                <div className="divForm">
                <label htmlFor="question3">Can I be invited to the group chat randomly</label>
                <select
                    id="question3"
                    name="question3"
                    value={answers.question3}
                    onChange={(e) => handleInputChange("question3", e.target.value)}
                    className="selectSettingsDivForm"
                >
                    <option value={question_three} disabled={isPaid === 2}>Yes</option>
                    <option value="Not" disabled={isPaid === 2}>Not</option>
                </select>
                </div>

                <div className="divForm">
                <label htmlFor="question4">Do I have associations in the quiz</label>
                <select
                    id="question4"
                    name="question4"
                    value={answers.question4}
                    onChange={(e) => handleInputChange("question4", e.target.value)}
                    className="selectSettingsDivForm"
                >
                    <option value={question_four} disabled={isPaid === 2}>Yes</option>
                    <option value="Not" disabled={isPaid === 2}>Not</option>
                </select>
                </div>

                </div>

                <button type="submit" className="settingsSubmitButton" ref={blockRef2}>Submit</button>
                <div className={notificationForSettingsSubmit === 1 ? "divDisabledSettings" : "notDisplayed"}>Settings are not available until a course is unlocked</div>
                </form>
                <div className="settingsDivButtons">
                <div className="settingsDivButtonsRow">
                <div className={loggedInWithGoogle === 1 ? "changePasswordSettings" : "disabledChangePasswordSettings"}  ref={blockRef} onClick={() => {linkToChangePassword()}}>Change password</div>
                <div className={notificationSettingsChangePassword === 1 ? "divDisabledChangingEmail" : "notDisplayed"}>You can't change password or email with google account</div>
                <div className={loggedInWithGoogle === 1 ? "changeEmailSettings" : "disabledChangeEmailSettings"} ref={blockRef3} onClick={() => {linkToChangeEmail()}}>Change Email</div>
                <div className={notificationSettingsChangeEmail === 1 ? "divDisabledChangingPassword" : "notDisplayed"}>You can't change password or email with google account</div>
                </div>
                <div className="removePageSettings" onClick={() => {removeChatFunction()}}>Remove page</div>
                <div className="logOut" onClick={handleLogout}>Log out</div>
                </div>
            </div>
            </div>

            <div className={askingBox === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to remove your page?</p>
                <p className="pRemovePageDescription">All the data will be removed without possibility to restore</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {removePageFunction()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff()}}>No</button>
                </div>
              </div>
            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
            <div className="alertDiv-content">
                <i className="check">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Success</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            <div className="progressDiv active"></div>
            </div>

            <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>

            <div className="alertDiv-content">
                <i className="checkWrong">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Ops</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            <div className="progressDiv active"></div>
            </div>
        </div>
    )
}