import React, { useEffect, useState } from "react"
import axios from 'axios'

export default function Main() {

    const [isPaid, setIsPaid] = useState(0)
    const [isPaid2, setIsPaid2] = useState(0)
    const [isPaid3, setIsPaid3] = useState(0)

    const is_paid =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
                //console.log(response.data.paid)
                if(response.data.paid === 1){
                    setIsPaid(response.data.paid)
                }else if (response.data.paid === 0){
                    setIsPaid(response.data.paid)
                    is_completed_twenty()
                }
        });
     }

     const is_paid2 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk2'}`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
                //console.log(response.data.paid)
                if(response.data.paid === 1){
                    setIsPaid2(response.data.paid)
                }else if (response.data.paid === 0){
                    setIsPaid2(response.data.paid)
                    is_completed_twenty2()
                }
        });
     }


     const is_paid3 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk3'}`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
                //console.log(response.data.paid)
                if(response.data.paid === 1){
                    setIsPaid3(response.data.paid)
                }else if (response.data.paid === 0){
                    setIsPaid3(response.data.paid)
                    is_completed_twenty3()
                }
        });
     }


     
    useEffect(() => {
        is_paid()
        const fetchData = async () => {
            await countScore();
        };

        fetchData();

        const fetchData2 = async () => {
            await countScore2();
        };

        fetchData2();

        const fetchData3 = async () => {
            await countScore3();
        };

        fetchData3();
    }, [])

    const [completedTwenty, setCompletedTwenty] = useState(0)
    const [percentage, setPercentage] = useState(0)

    const [completedTwenty2, setCompletedTwenty2] = useState(0)
    const [percentage2, setPercentage2] = useState(0)

    const [completedTwenty3, setCompletedTwenty3] = useState(0)
    const [percentage3, setPercentage3] = useState(0)

    const is_completed_twenty =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
            if (response.data.completed){
                setCompletedTwenty(response.data.completed)
            } 
        });
     }

     const is_completed_twenty2 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk2'}`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
            if (response.data.completed){
                setCompletedTwenty(response.data.completed)
            } 
        });
     }

     const is_completed_twenty3 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk3'}`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
            if (response.data.completed){
                setCompletedTwenty(response.data.completed)
            } 
        });
     }

     const countScore = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore`);
            if (response.data.score !== undefined) {
                setPercentage(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore2 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk2'}`);
            if (response.data.score !== undefined) {
                setPercentage2(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore3 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk3'}`);
            if (response.data.score !== undefined) {
                setPercentage3(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };
    
    return (
        <div className="main2">
            <img src="images/background_main.jpg" className="background"></img>
            <a href={completedTwenty === 5 ? "/paying" : (percentage === 1 ? "#" : "/quiz")} className="hskLink"></a>
            <a href={completedTwenty2 === 5 ? "/payingHSK2" : (percentage2 === 1 ? "#" : "/quizHSK2")} className="hsk2Link"></a>
            <a href={completedTwenty3 === 5 ? "/payingHSK3" : (percentage3 === 1 ? "#" : "/quizHSK3")} className="hsk3Link"></a>
        </div>
    )
}