import React, { useState } from 'react';
import axios from 'axios';

export default function PayingQuestionHSK2() {

    const paymentFunction = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_TWO}/paypalPaymentHSK2`;
      };

      const backToTestsLink = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_TWO}/courses`;
      };
      
    return (
        <div className="payingQuestionPage">
         <div className="theBlockPage">
                <div className="alertDivPayingSeparatePage">
                <></>
                <br/>
                <h2><div className="completedQuizTextTrial">The free part is completed</div></h2>
                <div className="completedQuizTrialDiv">
                <div className="textCompletedTrial">Access course with one payment!</div>
                <div className="payingText"><div className="trialHSK1">HSK2</div><div className="trialPlus">+</div> <div className="chatIncluded">online chat included</div>
                    <div className="price">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svgEuro" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M19 7.11111C17.775 5.21864 15.8556 4 13.6979 4C9.99875 4 7 7.58172 7 12C7 16.4183 9.99875 20 13.6979 20C15.8556 20 17.775 18.7814 19 16.8889M5 10H14M5 14H14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div className="paymentAmount"><div className="priceNumber">31</div>/<div className="timePayment">3 months</div></div>
                    </div>
                    </div>
                </div>
                
                    <button className="buttonPayTestTrial" onClick={() => {paymentFunction()}}>Pay</button>
                    <button onClick={() => {backToTestsLink()}} className="buttonBackToTestTrial">Back to Tests</button>
                </div>
                </div>
    </div>
    )
}