import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate, useLocation } from "react-router-dom"

import Question from "./Question"
import Description from "./Description"

const QuizOver = ({characters}) => {

    const [arrayOfChosenCharacters, setArrayOfChosenCharacters] = useState([]);

    const learnAgainFunction =(character) => {

        axios.post(`${process.env.REACT_APP_BACKEND}/learnAgainFunction`, {
            character: character
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            setArrayOfChosenCharacters(prevArray => [...prevArray, response.data.restarted]);
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    const [isPaid, setIsPaid] = useState(0)
    const [completed, setCompleted] = useState(0)

    useEffect(() => {
        is_paid()
    }, [])

    const is_paid =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
                if(response.data.paid === 1){
                    setIsPaid(response.data.paid)
                    //console.log('not paid 1')
                    is_completed()
                }else if (response.data.paid === 0){
                    //console.log('not paid 0')
                    setIsPaid(response.data.paid)
                    is_completed_twenty()
                }
        });
     }

     const is_completed =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_completed`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
            if (response.data.completed){
                setCompleted(response.data.completed)
            } 
        });
     }

     const is_completed_twenty =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty`).then((response) => {
            //setPost(response.data);
            //alert(JSON.stringify(response.data))
            if (response.data.completed != undefined){
                setCompleted(response.data.completed)
            } 
        });
     }


    const url = window.location.pathname;
    const linkName = url.substring(url.lastIndexOf('/') + 1);

    const navigate = useNavigate();

    const paymentFunction = () => {
        navigate(`/paypalPayment`)
      }; 
      
      const backToTestsLink = () => {
        navigate(`/courses`)
      };

    return (
        <>
            <div className="congratulationsBlockDiv">
               { completed === 0 ? 

               <div className="congratulationsBlockEmpty"></div> : 
               
               (completed === 2 || completed === 3 ?  
               
               <div className="congratulationsBlock">
               QUIZ IS OVER
               <br/>
               <h2><>:)</></h2>
               <p className="greetingWord">Congratulations!</p>
               <a href="/courses">
                   <p className="buttonBackToTest">Back to Tests</p>
               </a>
               </div> : 
               (completed === 1 ?  
               
               <div className="congratulationsBlock">
                    <></>
                    <br/>
                    <h2><div className="completedQuizText">Congratulations!</div></h2>
                    <p className="greetingWord"><div className="completedQuizTextSmall">The quiz was completed fully</div></p>
                    <a href="/courses">
                        <p className="buttonBackToTest">Back to Tests</p>
                    </a>
                </div>
                :
                <div className="theBlock">
                <div className="alertDivPaying">
                <></>
                <br/>
                <h2><div className="completedQuizTextTrial">The free part is completed</div></h2>
                <div className="completedQuizTrialDiv">
                <div className="textCompletedTrial">Access course with one payment!</div>
                <div className="payingText"><div className="trialHSK1">HSK1</div><div className="trialPlus">+</div> <div className="chatIncluded">online chat included</div>
                    <div className="price">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svgEuro" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M19 7.11111C17.775 5.21864 15.8556 4 13.6979 4C9.99875 4 7 7.58172 7 12C7 16.4183 9.99875 20 13.6979 20C15.8556 20 17.775 18.7814 19 16.8889M5 10H14M5 14H14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div className="paymentAmount"><div className="priceNumber">15</div>/<div className="timePayment">1 month</div></div>
                    </div>
                    </div>
                </div>
                
                    <button className="buttonPayTestTrial" onClick={() => {paymentFunction()}}>Pay</button>
                    <button onClick={() => {backToTestsLink()}} className="buttonBackToTestTrial">Back to Tests</button>
                </div>
                </div>
                )
                )}

                <div className="learnedWords">
                    
                    <div className="learnedWordsText">Words you were studying:</div>
        
                    {
                        characters && characters.length > 0 ? (
                                characters.map((character, index) => {
                                    return (
                                        <div className="descriptionListCongrPage" key={index}>
                                        <p className="mainWordList">{character.character}</p>
                                        <p className="translationWordList">{character.translation}</p>
                                        <p className="radicalsDescriptionList">{character.hintsRadicals}</p>
                                        <p className="radicalsTranslationList">{character.hintsRadicalTranslations}</p>
                                        <p className="wordDescriptionListCongrPage">{character.info}</p>
                                        <br/>
                                        <div className="svgBlockCongrPage">
                                        {
                                            linkName === "quiz" ? null : <svg className="repeatWordSvg" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                                            <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                                            </svg>

                                        }
                                        <div className="divForLearnWordSvg">
                                        <svg className={linkName === "quiz" ? (arrayOfChosenCharacters.includes(`${character.character} restarted`) ? "learnAgainSvgCursor": "learnAgainSvg2") : "learnAgainSvg"} onClick={() => {learnAgainFunction(character.character)}} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 -8.14 62.451 62.451">
                                        <g id="Group_35" data-name="Group 35" transform="translate(-782.964 -1356.609)">
                                            <path id="Path_92" data-name="Path 92" d="M798.022,1369.359v23.226h.034c.353,4.555,7.685,8.2,16.7,8.2s16.347-3.641,16.7-8.2h.033v-23.226Z" fill="#d1d3d4" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                            <path id="Path_93" data-name="Path 93" d="M843.415,1373.207l-29.225,14.6-29.227-14.6,29.227-14.6Z" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                            <line id="Line_40" data-name="Line 40" y1="19.235" transform="translate(784.964 1374.361)" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                        </g>
                                        </svg>
                                        <div className="learnWordTextQuiz">learn the word again</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={arrayOfChosenCharacters.includes(`${character.character} restarted`) ? (linkName === "quiz" ? "checkDoneList2" : "checkDoneList") : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                                        </svg>
                                        </div>
                        </div>
                                    )
                                })  ) : (
                                    <div>Nothing here</div>
                                )
                                                
                    }
                </div>
            </div>
        </>
    )
}

export default function Quiz() {

    const [questions, setQuestions] = useState(undefined)
    const [characters, setCharacters] = useState(undefined)
    const [isAnswering, setIsAnswering] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    const [characterDisplayList, setCharacterDisplayList] = useState([]) // description list
    const [visitedCharacters, setVisitedCharacters] = useState([])
    const [maxScore, setMaxScore] = useState(0)
    const [currentScore, setCurrentScore] = useState(0)
    const [displayHint3, setDisplayHint3] = useState(0)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        const fetchQuiz = async () => {
            axios.get(`${process.env.REACT_APP_BACKEND}/quiz/getQuiz`).then(res => {
                // alert(JSON.stringify(res.data.questions[0]))
                setQuestions(res.data.questions)
                setCharacters(res.data.characters)
                setMaxScore(res.data.questions.length)
                setInitialized(true)
                //console.log('questions', res.data.questions)
                //console.log('characters', res.data.characters)
                //console.log('length', res.data.questions.length)
            })
        }

        fetchQuiz()
    }, [])

    const removeQuestion = async() => {

        const temp_questions = [...questions]
        const first = temp_questions.shift()
        if(first) {
            //alert(JSON.stringify(first))
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND}/quiz/answer`, {
                    user_id: 10,
                    character: first.character,
                    question_index: first.index
                }, {});
                // Process the response if needed
                // alert("DONE")
            } catch (error) {
                alert(error.message);
            }
        }
        setQuestions(temp_questions)
        setCurrentScore(currentScore + 1)
        
    }

    const scrambleAnswers = () => {
        if(questions.length < 1)
            return

        const [first, ...rest] = questions
        //console.log(first.answers)
        first.answers.sort(() => Math.random() - 0.5)
        setQuestions([first, ...rest])
        

    }

    const swapQuestion = () => {
        if(questions.length < 2)
            return
        const [first, second, ...rest] = questions

        if(first.swappable === true) {
            first.swappable_attempts += 1
            if(first.swappable_attempts >= 2) {
                // move to back
                setQuestions([second, ...rest, first])
            } else {
                setQuestions([second, first, ...rest])
            }
        }else if(first.character !== second.character) {
            setQuestions([second, first, ...rest])
        }

        //if(first.swappable === true || first.character !== second.character) {
        //    setQuestions([second, first, ...rest])
        //}
        //if(first.character === second.character && first.swappable === true)
        //    setQuestions([second, first, ...rest])
    }

    const [answered, setAnswered] = useState(false)
    const checkAnswer = (answer, index, input, emptyInput) => {
        setAnswered(true)
        //console.log('answer is', answer)
        //console.log('index is', index)
        if(isAnswering === true)
            return; // already in progress
        setIsAnswering(true)
        setHighlightedIndex(index)
        //console.log('emptyInput', emptyInput)
        if(input || emptyInput){
               //console.log('input', input) 
               if(answer.answer === input && emptyInput != 'wrong word in hsk'){
                //console.log('answer is correct')
                setDisplayHint3(1)
                setTimeout(() => {
                    removeQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setDisplayHint3(0)
                    setAnswered(false)
                }, 1300)
               }else{
                //console.log('answer is wrong')
                setTimeout(() => {
                    addCurrentCharacterDescription()
                    scrambleAnswers()
                    swapQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setAnswered(false)
                }, 1300)
               }
        } else{
            if(answer.correct) {
                //console.log('answer is correct 2')
                setDisplayHint3(1)
                setTimeout(() => {
                    removeQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setDisplayHint3(0)
                    setAnswered(false)
                }, 1300)
            } else {
                //console.log('answer is wrong 2')
                setTimeout(() => {
                    addCurrentCharacterDescription()
                    scrambleAnswers()
                    swapQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setAnswered(false)
                }, 1300)
            }
            
        }
        
    }


    const addCurrentCharacterDescription = () => {
        //console.log('addCurrentCharacterDescription')
        if(characters && questions && questions.length > 0) {
            //console.log('inside')
            const [...temp] = characterDisplayList
            for(let i=0;i<characters.length;i++) {
                //console.log('loop')
                //console.log('characters[i].character', characters[i].character)
                //console.log('questions[0].character', questions[0].character)
                if(characters[i].character === questions[0].character) {
                    //console.log('if statement')
                    temp.push(characters[i])
                    break
                }
            }
            setCharacterDisplayList(temp)
        }
        
    }

    const addNewDescription = () => {
        //console.log('addNewDescription')

        // Add description if current question hasn't displayed the description yet

        if(characters && questions && questions.length > 0) {
            //console.log('inside addNewDescription')

            //console.log("Q0", questions[0])

            for(let i=0;i<visitedCharacters.length;i++) {
                //console.log('questions[0].character', questions[0].character)
                //console.log('visitedCharacters[i]', visitedCharacters[i])
                if(questions[0].character === visitedCharacters[i]) {
                    //console.log('it will return')
                    return
                }
            }

            for(let i=0;i<characters.length;i++) {
                if(characters[i].character === questions[0].character) {
                    //console.log('inside second loop')
                    const [...temp2] = visitedCharacters
                    const [...temp1] = characterDisplayList
                    temp2.push(questions[0].character)
                    temp1.push(characters[i])
                    //console.log('temp1', temp1)
                    //console.log('temp2', temp2)
                    setVisitedCharacters(temp2)
                    // remove this question if you want description when returning to quiz
                    //console.log('questions[0]', questions[0])
                    //console.log('questions[0].swappable', questions[0].swappable)
                    if(questions[0].swappable !== true) {
                        //console.log('questions[0].swappable !== true')
                        setCharacterDisplayList(temp1)
                    }
                }
            }

            
            
            
            
        }
    }

    addNewDescription() // it's inside here

    const removeCurrentDescription = () => {
        const [...temp] = characterDisplayList
        temp.shift()
        setCharacterDisplayList(temp)
    }

    /*return (
        <div className="test">
            <QuizOver characters={characters}/>
        </div>
    )*/

        const removeQuestionAndDescription = async() => {

            // Create a copy of the character display list and identify the needed character
           const [...temp] = characterDisplayList;
           const neededCharacter = temp[0]?.character;
           
           // Remove the first item from the display list
           temp.shift();
           
           // Create a copy of the current questions
           const temp_questions = [...questions];
           
           // Filter out the questions that match the needed character
           const matchingQuestions = temp_questions.filter(question => question.character === neededCharacter);
           
           if (matchingQuestions.length === 0) return; // No matching questions, nothing to do
           
           const count = matchingQuestions.length;

           try {
               // Process all matching questions in parallel using Promise.all
               await Promise.all(
                   matchingQuestions.map(async (question) => {
                       await axios.post(`${process.env.REACT_APP_BACKEND}/quiz/answer`, {
                           user_id: 10,
                           character: question.character,
                           question_index: question.index,
                       });
                   })
               );

               // Remove the processed questions from the questions array
               const updatedQuestions = questions.filter(question => question.character !== neededCharacter);
               
               // Update the state after all operations are done
               setCharacterDisplayList(temp); // Update the character display list
               setQuestions(updatedQuestions); // Update the questions array
               setCurrentScore((prevScore) => prevScore + count); // Update the score
               
           } catch (error) {
               alert(error.message); // Handle any errors that occur during the process
           }
       }

    return (
        <div className="test">
            {
            characterDisplayList.length > 0 ? 
                <Description character = {characterDisplayList[0]} remove={removeCurrentDescription} removeQuestionAndDescription={removeQuestionAndDescription}/>
                :
                (questions && questions.length > 0) ?
                    <Question checkAnswer={checkAnswer} question={questions[0]} highlightedIndex={highlightedIndex} displayHint3={displayHint3} currentScore={currentScore} maxScore={maxScore} answered={answered}/>
                    :
                        (initialized === false) ? 
                            <div className="main"></div>
                        :
                            <QuizOver characters={characters}/>
            }
        </div>
    )

}